// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"breakpointxs": "0",
	"breakpointsm": "640",
	"breakpointmd": "992",
	"breakpointlg": "1200",
	"breakpointxl": "1440",
	"breakpoint2xl": "1920",
	"breakpoint3xl": "2400",
	"color-black": "#000",
	"color-white": "#fff",
	"color-gray": "#100810",
	"color-primary": "#ee62d8",
	"color-pink-100": "#ee62d8",
	"color-pink-50": "#f7b1ec",
	"color-pink-25": "#fbd8f5",
	"color-red-100": "#c8002d",
	"color-red-50": "#e38096",
	"color-red-25": "#f1bfca",
	"color-yellow-100": "#f4cf00",
	"color-yellow-50": "#fae780",
	"color-yellow-25": "#fcf3bf",
	"color-dark": "#100810",
	"color-light": "#e7e6e7"
};
module.exports = ___CSS_LOADER_EXPORT___;
